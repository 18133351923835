<style>
.basket-toggle-btn .v-badge__badge{inset: calc(100% - 13px) calc(100% - 22px) auto auto!important;font-size: 12px;min-width: 15px;height: 15px;padding: 3px;}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0
}
</style>
<style scoped>
.basket-toggle-btn{box-shadow: -3px 1px 5px #00000030;position: fixed;right: 0;top: 110px;z-index: 6;background: #2ea6f5;height: 38px;width: 38px;border-radius: 50% 0px 0 50%;padding: 5px 12px;cursor:pointer}
.outer-wrapper{float:left;width:100%;min-height: 1px;transition: all .1s;}
.basket-open .outer-wrapper{width:calc(100% - 350px);}
.basket-wrapper{width:350px;float:left;height:calc(100vh - 78px);position: sticky;top: 72px;display:none}
.basket-open .basket-wrapper{display:block}
</style>

<template>
  <div class="f-semibold" >
    <sidebar />
    <v-main :class="{'basket-open':basketGval.showBasket==true}">
      <transition>
        <div class="outer-wrapper"><router-view/></div>
      </transition>
      <transition name="fade">
        <basket class="basket-wrapper" v-if="basketGval.showBasket" />
      </transition>
    </v-main>
    <orderWindow v-if="showOrderWindow" />
    
    <div class="basket-toggle-btn" @click="basketGval.showBasket = true" v-if="!basketGval.showBasket">
      <v-badge :value="basketGval.basketList.length" :content="basketGval.basketList.length" color="#D63031">
        <img class="" src="@/assets/images/optionchain/bag.svg" alt=""/>
      </v-badge>
    </div>
  </div>
</template>

<script>
import sidebar from "../views/sidebar.vue";
import { mapState, mapMutations  } from 'vuex'

import { gql } from "graphql-tag";

const INSTRUMENTS_HISTORY_QUERY = gql`
  query instrumentsHistory($instruments: [String]) {
    instrumentsHistory(instruments: $instruments) {
      instrument
      data {
        INSTRUMENTIDENTIFIER
        OPEN
        HIGH
        LOW
        CLOSE
        OPENINTEREST
        TRADEDQTY
        EXCHANGE
        LASTTRADETIME
      }
    }
  }
`;
let instrumentsHistory ={}

export default {
  data: () => ({}),
    methods: {
      // ...mapMutations(['updateInstrumentsHistory']),
    },
    
  components: {sidebar,
   orderWindow: () => import(/*webpackChunkName: "orderWindow" */ "../views/orderWindow.vue" ),
   basket: () => import("../views/basket.vue" )
  },
  computed: {...mapState(['s_instrumentsHistory',"basketGval"]),
  ...mapState("orderWindow", ["showOrderWindow"]),
  },

  apollo: {
    instrumentsHistory: {
      query: INSTRUMENTS_HISTORY_QUERY,
      variables() {
        return {
          instruments: [
            // this.socketVariables,
            // `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_PE_${this.peStrikeprice}`,
            // `OPTIDX_${this.instrument}_${this.expirydate != null ? this.expirydate.replace('21', '2021') : ''}_CE_${this.ceStrikeprice}`,
            'OPTIDX_BANKNIFTY_28OCT2021_PE_41200',
            'OPTIDX_BANKNIFTY_28OCT2021_CE_41200'
          ],
        };
      },
      update: (data) => {
        instrumentsHistory = data.instrumentsHistory;
        return instrumentsHistory;
      },
      result() {
      // this.updateInstrumentsHistory(this.instrumentsHistory)
      }
    },
    
  },

  mounted() {
    console.log(this.basketGval)
      const theme = localStorage.getItem("dark_theme");
      if (theme) {
          if (theme == "true") {
              this.$vuetify.theme.dark = true;
          } else {
              this.$vuetify.theme.dark = false;
          }
      }
  },
}
</script>